import { render, staticRenderFns } from "./reasonForNonCompliance-edit.vue?vue&type=template&id=fb3ab442&scoped=true&"
import script from "./reasonForNonCompliance-edit.vue?vue&type=script&lang=js&"
export * from "./reasonForNonCompliance-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3ab442",
  null
  
)

export default component.exports